<template>
  <div class="footer-nav border-top pr-4 pl-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>

<style scoped>
.footer-nav {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  min-height: 5rem;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.footer-nav > *:last-child {
  margin-right: 5rem;
}
</style>
