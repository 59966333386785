




















import { Component, Prop, Vue } from 'vue-property-decorator';

interface Tab {
  id: string;
  label: string;
}

@Component({
  name: 'TabList',
})
export default class  extends Vue {
  @Prop() tabs!: Tab[];

  activeTab = '';

  mounted():void {
    this.activeTab = this.tabs[0].id ?? '';
  }
  tabClicked(tgt: string): void {
    this.activeTab = tgt;
    this.$emit('tabClicked', this.activeTab);
  }
}
