<template>
  <div class="form-row">
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('to') }"
    >
      <label for="to" class="control-label text-capitalize">To:</label>
      <input
        id="to"
        v-model="formData.to"
        v-validate="{ required: true }"
        type="text"
        name="to"
        class="form-control"
      />
      <span v-show="errors.has('to')" class="invalid-feedback">{{
        errors.first("to")
      }}</span>
    </div>
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('subject') }"
    >
      <label for="subject" class="control-label text-capitalize"
        >Subject:</label
      >
      <input
        id="subject"
        v-model="formData.subject"
        v-validate="{ required: true }"
        type="text"
        name="subject"
        class="form-control"
      />
      <span v-show="errors.has('subject')" class="invalid-feedback">{{
        errors.first("subject")
      }}</span>
    </div>
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('message') }"
    >
      <label for="message" class="control-label text-capitalize"
        >Message:</label
      >
      <textarea
        id="message"
        v-model="formData.message"
        v-validate="{ required: true }"
        name="message"
        rows="6"
        class="form-control"
      />
      <span v-show="errors.has('message')" class="invalid-feedback">{{
        errors.first("message")
      }}</span>
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :title="'Send test'"
        :class="'btn btn-primary float-right'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import jsonAPI from "@/api";
import ButtonLoading from "../Form/ButtonLoading.vue";

export default {
  name: "MailTestModal",
  components: { 
    ButtonLoading,
  },
  props: {
    configuration: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        to: null,
        subject: null,
        message: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "modalDataID",
      "modalData",
      "modalActive",
      "senderEmail",
      "senderName",
    ]),
  },
  methods: {
    ...mapActions(["closeModal", "resetModalData"]),
    resetFormData() {
      this.formData = {
        to: null,
        subject: null,
        message: null,
      };
      this.$emit("hideComponent");
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        const data = this.formData;
        // get name and email from computed
        data.from_name = this.senderName;
        data.from_email = this.senderEmail;
        data.configuration = this.configuration;
        if (result) {
          jsonAPI
            .post("/organization/mail/test", data)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Test send",
                type: "success",
              });
              this.closeEmailModal();
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        }
        this.loading = false;
      });
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>
