<template>
  <component :is="layout">
    <base-modal v-if="modalActive">
      <component :is="component" :configuration="mailConfigData" />
    </base-modal>
    <div class="row fadeInUp content-body" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <div class="card border-0 shadow-sm">
              <div class="card-header">
                <div v-if="
                  template_option &&
                  template_option !== 'Create Template' &&
                  template_option !== 'Create or Edit Template'
                ">
                  <h4>Edit template</h4>
                </div>
                <div v-else>
                  <h4>Create template</h4>
                </div>
              </div>
              <div class="
                  card-body">
                <div class="form-group row col-12">
                  <label for="templates" class="col-sm-2 col-form-label">Templates</label>
                  <div class="col-sm-10">
                    <Multiselect :id="'templates'" v-model="template_option" :invalid="errors.has('templates')"
                      :options="templates" :searchable="true" :allow-empty="true" :show-labels="false"
                      @input="onChangeTemplate" />
                  </div>
                </div>
                <div v-if="
                  template_option === 'Create or Edit Template' ||
                  template_option === 'Create Template'
                " class="form-group row col-12 text-muted">
                  <p class="border-bottom pb-2">
                    Create a new email template form your automated email
                    configuration. This template will be available for you to
                    use in the email automation actions once you have saved it.
                  </p>
                </div>
                <div v-if="template_option" class="row">
                  <div class="col-12 col-md-8">
                    <div class="form-row col-12">
                      <div class="form-group col-12 required" :class="{ 'is-invalid': errors.has('name') }">
                        <label for="name" class="control-label">Template name:</label>
                        <input id="name" v-model="mailConfigData.name" v-validate="{ required: true }" type="text"
                          name="name" class="form-control" />
                        <span v-show="errors.has('name')" class="invalid-feedback">{{ errors.first("name") }}</span>
                      </div>
                    </div>

                    <TabList :tabs="tabs" @tabClicked="tabClick" class="form-row col-12" />
                    <div v-if="activeTab === 'design'" class="form-row col-12">
                      <div class="form-group col-md-4">
                        <label for="background_color" class="control-label">Background color:</label>
                        <input id="background_color" v-model="mailConfigData.background_color" type="text"
                          name="background_color" class="form-control" />
                        <div class="d-block mt-3">
                          <ColorSelect v-for="color in bg_colors" :key="color" :style="{ background: color }" :class="[
                            {
                              active_color:
                                color === mailConfigData.background_color,
                            },
                          ]" @click.native="chooseColor('background_color', color)" />
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="header_color" class="control-label">Header color:</label>
                        <input id="header_color" v-model="mailConfigData.header_color" type="text" name="header_color"
                          class="form-control" />
                        <div class="d-block mt-3">
                          <ColorSelect v-for="color in bg_colors" :key="color" :style="{ background: color }" :class="[
                            {
                              active_color:
                                color === mailConfigData.header_color,
                            },
                          ]" @click.native="chooseColor('header_color', color)" />
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="footer_color" class="control-label">Footer color:</label>
                        <input id="footer_color" v-model="mailConfigData.footer_color" type="text" name="footer_color"
                          class="form-control" />
                        <div class="d-block mt-3">
                          <ColorSelect v-for="color in bg_colors" :key="color" :style="{ background: color }" :class="[
                            {
                              active_color:
                                color === mailConfigData.footer_color,
                            },
                          ]" @click.native="chooseColor('footer_color', color)" />
                        </div>
                      </div>
                    </div>
                    <div v-if="activeTab === 'text'" class="form-row col-12">
                      <div class="form-group col-md-4">
                        <label for="font_color" class="control-label">Font color:</label>
                        <input id="font_color" v-model="mailConfigData.font_color" type="text" name="font_color"
                          class="form-control" />
                        <div class="d-block mt-3">
                          <ColorSelect v-for="color in font_colors" :key="color" :style="{ background: color }" :class="[
                            {
                              active_color: color === mailConfigData.font_color,
                            },
                          ]" @click.native="chooseColor('font_color', color)" />
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="button_color" class="control-label">Button text color:</label>
                        <input id="button_color" v-model="mailConfigData.button_color" type="text" name="button_color"
                          class="form-control" />
                        <div class="d-block mt-3">
                          <ColorSelect v-for="color in button_colors" :key="color" :style="{ background: color }"
                            :class="[
                              {
                                active_color:
                                  color === mailConfigData.button_color,
                              },
                            ]" @click.native="chooseColor('button_color', color)" />
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="feature_color" class="control-label">Feature color:</label>
                        <input id="feature_color" v-model="mailConfigData.feature_color" type="text"
                          name="feature_color" class="form-control" />
                        <div class="d-block mt-3">
                          <ColorSelect v-for="color in colors" :key="color" :style="{ background: color }" :class="[
                            {
                              active_color:
                                color == mailConfigData.feature_color,
                            },
                          ]" @click.native="chooseColor('feature_color', color)" />
                        </div>

                      </div>
                      <div class="form-group col-md-4">
                        <label for="alignheader" class="control-label">Header alignment</label>
                        <select
                          id="alignheader"
                          v-model="mailConfigData.alignheader"
                          name="alignheader"
                          class="form-control"
                        >
                          <option value="left" key="left" :selected="mailConfigData.alignheader === 'left'">Left</option>
                          <option value="center" key="center" :selected="mailConfigData.alignheader === 'center'">Center</option>
                          <option value="right" key="right" :selected="mailConfigData.alignheader === 'right'">Right</option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="aligntext" class="control-label">Text alignment</label>
                        <select
                          id="aligntext"
                          v-model="mailConfigData.aligntext"
                          name="aligntext"
                          class="form-control"
                        >
                          <option value="left" key="left" :selected="mailConfigData.aligntext === 'left'">Left</option>
                          <option value="center" key="center" :selected="mailConfigData.aligntext === 'center'">Center</option>
                          <option value="right" key="right" :selected="mailConfigData.aligntext === 'right'">Right</option>
                        </select>
                      </div>
                      <div class="form-group col-12">
                        <h5>Logo</h5>
                        <hr />
                      </div>
                      <div class="form-group col-md-6" :class="{ 'is-invalid': errors.has('logo') }">
                        <label for="logo" class="control-label">Logo:</label>
                        <image-uploader id="logo" url="organization/mail/logo" :image-path="mailConfigData.logo"
                          @image-changed="uploadSuccess" />
                      </div>
                      <div class="form-group col-md-6" :class="{ 'is-invalid': errors.has('logo_max_height') }">
                        <label for="logo_max_height" class="control-label">Logo max height:</label>
                        <div class="input-group">
                          <input id="logo_max_height" v-model="mailConfigData.logo_max_height" type="text"
                            name="logo_max_height" class="form-control" />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              px
                            </div>
                          </div>
                        </div>
                        <span v-show="errors.has('logo_max_height')" class="invalid-feedback">{{
                            errors.first("logo_max_height")
                        }}</span>
                      </div>
                      <div class="form-group col-md-6" :class="{ 'is-invalid': errors.has('locale') }">
                        <label for="locale" class="control-label">Locale:</label>
                        <multiselect id="locale" v-model="mailConfigData.locale" name="locale" :options="countries"
                          label="name" track-by="code" :show-labels="false" />
                        <span v-show="errors.has('locale')" class="invalid-feedback">{{ errors.first("locale") }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <h4 class="text-center">
                      Email template preview
                    </h4>
                    <div class="m-3 border" :style="{
                      'background-color': mailConfigData.background_color,
                      'text-align': mailConfigData.aligntext ? mailConfigData.aligntext : 'left',
                    }">
                      <div class="p-4" :style="{
                        'background-color': mailConfigData.header_color,
                      }">
                        <img :src="mailConfigData.logo" :style="{
                          'max-height': mailConfigData.logo_max_height + 'px',
                        }" />
                      </div>
                      <div class="body pt-6 pr-5 pl-5">
                        <h3 :style="{ color: mailConfigData.font_color, 'text-align': mailConfigData.aligntext ? mailConfigData.aligntext : 'left', }">
                          Lorem ipsum dolor sit amet
                        </h3>
                        <p class="mb-5" :style="{ color: mailConfigData.font_color, 'text-align': mailConfigData.aligntext ? mailConfigData.aligntext : 'left', }">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Maecenas egestas gravida diam, laoreet bibendum
                          nunc ornare ut. Vestibulum purus ante, consequat nec
                          arcu sed, ullamcorper scelerisque elit. Phasellus
                          volutpat placerat orci sed vehicula. Ut sagittis
                          ornare orci ut rutrum. Aenean odio magna, auctor
                          faucibus dolor ac, scelerisque gravida nibh. Nunc
                          felis nisi, sodales non semper porttitor, feugiat ut
                          nunc.
                        </p>
                        <button
                          class="btn btn-lg btn-dark mb-6"
                          :style="{
                            background: mailConfigData.feature_color,
                            color: mailConfigData.button_color,
                          }"
                        >
                          Click me
                        </button>
                        <hr />
                      </div>
                      <div class="footer p-4" :style="{
                        'background-color': mailConfigData.footer_color,
                        color: mailConfigData.font_color,
                      }">
                        <p class="small text-center">
                          Copyright © {{ current_year }} {{ getName }}, All
                          rights reserved.
                        </p>
                        <p class="mb-1 small">
                          Want to change how you receive these emails?
                        </p>
                        <p class="small mb-0">
                          <span class="pb-2">Unsubscribe from this list</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer config-buttons">
                <button v-if="
                  template_option &&
                  template_option !== 'Create or Edit Template' &&
                  template_option !== 'Create Template'
                " :loading="loading" class="btn btn-outline-danger delete-template" @click="deleteTemplate">
                  Delete template
                </button>

                <button :loading="loading" class="btn btn-secondary btn-template" @click="saveEmailConfig">
                  Save configuration
                </button>
                <button :loading="loading" class="btn btn-secondary btn-template" @click="openMailTestModal">
                  Test email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Default from "@/layouts/default.vue";
import ax from "axios";
import jsonAPI from "@/api";
import BaseModal from "@/components/UI/BaseModal.vue";
import MailTestModal from "@/components/UI/MailTestModal.vue";
import ImageUploader from "@/components/UI/ImageUploader.vue";
import ColorSelect from "@/components/UI/ColorSelect.vue";
import FooterBar from "@/components/Nav/FooterBar.vue";
import TabList from "@/components/UI/TabList.vue";
import countries from "@/helpers/countries";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";

export default {
  components: {
    BaseModal,
    Default,
    MailTestModal,
    ImageUploader,
    ColorSelect,
    FooterBar,
    Multiselect,
    TabList,
    ConfigurationNav
},
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  beforeRouteEnter(to, from, next) {
    const promises = [
      jsonAPI.get("/organization/mail/settings"),
      jsonAPI.get("/user/current"),
      jsonAPI.get("/configuration/template?per_page=100"),
    ];
    ax.all(promises).then(([response, userResponse, templateResponse]) => {
      next((vm) => {
        vm.mail_drivers = response.data.drivers;
        vm.mailConfigData = Object.assign(
          vm.mailConfigData,
          response.data.settings
        );

        // get mail driver
        if (response.data.settings.mail_driver) {
          vm.mail_driver = response.data.settings.mail_driver;
        }

        // check if from_mail is empty and use current user email and name
        if (!vm.mailConfigData.from_mail) {
          vm.mailConfigData.from_mail = userResponse.data.email;
          vm.mailConfigData.from_name = userResponse.data.name;
        }
        vm.setTemplates(templateResponse);
        vm.$store.dispatch("closeModal");
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(() => {
      this.$store.dispatch("closeModal");
    });
  },
  data() {
    return {
      layout: "Default",
      activeTab: "design",
      current_year: new Date().getFullYear(),
      mailConfigData: {
        wrapper_color: "transparent",
        background_color: "#c2cae7",
        header_color: "#c2cae7",
        footer_color: "#c2cae7",
        feature_color: "#1fbc9c",
        button_color: "#ffffff",
        font_color: "#000000",
        alignheader: "center",
        aligntext: "left",
        logo_max_height: 500,
        logo: "",
      },
      current_template: "",
      current_name: "",
      template_option: "",
      loading: false,
      component: null,
      mail_drivers: [],
      templates: [],
      templatesData: [],
      colors: [
        "",
        "#1fbc9c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
      ],
      font_colors: ["#ffffff", "#000000"],
      button_colors: ["#ffffff", "#000000"],
      bg_colors: [
        "",
        "#ffffff",
        "#000000",
        "#c2cae7",
        "#d2c1dc",
        "#bfdcd4",
        "#fdd1bb",
      ],
      step_status: {
        key: "create_email_template",
        status: true,
      },
      countries: countries,
      tabs: [
        {
          label: "Design",
          id: "design",
        },
        {
          label: "Text",
          id: "text",
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["getOrganization", "modalActive"]),
    getName() {
      return this.getOrganization.title ?? "";
    },
  },
  methods: {
    async saveEmailConfig() {
      console.log("saveEmailConfig", this.current_template)
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const uniqueName = this.templates.filter((template) => {
            return template === this.mailConfigData.name;
          });
          if (uniqueName.length > 0) {
            const checkName = !this.current_template || this.current_name !== this.mailConfigData.name;
            if (checkName) {
              console.log("template name not unique")
              this.loading = false;
              this.$validator.errors.add({
                id: "name",
                field: "name",
                msg: "Template names must be unique to avoid confusion.",
                scope: this.$options.scope,
              });
              this.$validator.fields.find({
                name: "name",
                scope: this.$options.scope,
              }).setFlags({
                invalid: true,
                valid: false,
                validated: true,
              });
              window.Bus.$emit("flash-message", {
                text: "Please choose a different template name",
                type: "error",
              });
              return;
            }
          }
          const data = {
            organization_id: this.getOrganization.id,
            data: this.mailConfigData,
            group: "template",
          };
          if (this.current_template) {
            this.updateTemplate(data);
          } else {
            this.createTemplate(data);
          }
        }
        this.loading = false;
      });
    },
    async createTemplate(data) {
      const url = "/configurationData";
      jsonAPI
        .post(url, data)
        .then(() => {
          // mark step complete
          this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);

          window.Bus.$emit("flash-message", {
            text: "Email settings updated",
            type: "success",
          });
          jsonAPI
            .get("/configuration/template?per_page=100")
            .then((response) => {
              this.setTemplates(response);
            });
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.$validator.errors.add({
              field: key,
              msg: error.response.data.errors[key][0],
            });
          });
          window.Bus.$emit("flash-message", {
            text: "error main",
            type: "error",
          });
        });
    },
    updateTemplate(data) {
      const url = `/configurationData/${this.current_template}`;
      jsonAPI
        .put(url, data)
        .then(() => {
          window.Bus.$emit("flash-message", {
            text: "Email settings updated",
            type: "success",
          });
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.$validator.errors.add({
              field: key,
              msg: error.response.data.errors[key][0],
            });
          });
          window.Bus.$emit("flash-message", {
            text: "error main",
            type: "error",
          });
        });
    },
    setTemplates(resp) {
      this.templates = [];

      this.templates.push("Create Template");

      if (resp.data.data) {
        const templateKeys = resp.data.data.map((item) => {
          return item.data.name;
        });
        this.templates = this.templates.concat(templateKeys);
      }
      this.templatesData = resp.data.data;
    },
    openMailTestModal() {
      this.component = "MailTestModal";
      this.$store.dispatch("openModal", {
        title: "Test Mail Configuration",
        sender_email: this.mailConfigData.from_mail,
        sender_name: this.mailConfigData.from_name,
      });
    },
    chooseColor(type, color) {
      this.mailConfigData[type] = color;
    },
    uploadSuccess: function (path) {
      this.mailConfigData.logo = path;
    },
    onChangeTemplate(key) {
      if (key === "Create or Edit Template" || key === "Create Template") {
        this.mailConfigData = {
          wrapper_color: "transparent",
          background_color: "#c2cae7",
          header_color: "#c2cae7",
          footer_color: "#c2cae7",
          feature_color: "#1fbc9c",
          button_color: "#ffffff",
          font_color: "#000000",
          logo_max_height: 30,
          logo: "",
        };
        this.current_template = "";
      } else if (key) {
        const template = this.templatesData.find((item) => {
          return item.data.name === key;
        });
        this.mailConfigData = template.data;
        this.current_template = template.id;
        this.current_name = template.data.name;
      }
    },
    deleteTemplate() {
      this.loading = true;
      const data = {
        organization_id: this.getOrganization.id,
        data: this.mailConfigData,
        group: "template",
      };
      const url = `/configurationData/${this.current_template}`;
      jsonAPI
        .delete(url, data)
        .then(() => {
          jsonAPI
            .get("/configuration/template?per_page=100")
            .then((response) => {
              this.setTemplates(response);
            });
          this.template_option = "";
          this.loading = false;
          window.Bus.$emit("flash-message", {
            text: "Email templated deleted",
            type: "success",
          });
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.$validator.errors.add({
              field: key,
              msg: error.response.data.errors[key][0],
            });
          });
          window.Bus.$emit("flash-message", {
            text: "error main",
            type: "error",
          });
        });
    },
    tabClick(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
.content-body {
  margin-bottom: 200px;
}

/* .config-buttons {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    gap: 1rem
} */
.config-buttons .btn-template {
  margin-left: 1rem;
  float: right;
}
</style>
